import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import createAltString from '../helpers/create-alt-string';

const OptionalServicesImage = () => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "optional-services.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            originalName
          }
        }
      }
    }
  `);

  const {
    fluid,
    fluid: { originalName },
  } = image.placeholderImage.childImageSharp;
  const alt = createAltString(originalName);
  return <Img className="no-fuzz" style={{ maxWidth: '200px' }} fluid={fluid} alt={alt} />;
};

export default OptionalServicesImage;
