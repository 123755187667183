import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LodgingImage from '../components/lodging-image';
import OptionalServicesImage from '../components/optional-services-image';

const MissionPage = () => (
  <Layout>
    <SEO description="Services we offer for our care home clients and families." title="Services" />
    <h1>Services</h1>
    Pricing is based on level of care and location. More services may be considered by request.
    <h2>Lodging</h2>
    <div className="grid-two third-to-two-third">
      <ul>
        <li>Choice between private and shared bedroom</li>
        <li>3 daily nutritious meals and snacks in between</li>
        <li>Special diet provided as prescribed by a physician</li>
        <li>Hygiene items for general use</li>
        <li>Laundry and linen services provided</li>
        <li>Planned activity program</li>
        <li>Assistance with ADL&apos;s</li>
        <li>Assistance with taking prescribed medications</li>
        <li>Assistance with meeting medical and dental care</li>
      </ul>
      <LodgingImage />
    </div>
    <h2>Optional Services</h2>
    <div className="grid-two third-to-two-third">
      <ul>
        <li>Insulin</li>
        <li>Liquid narcotic medication</li>
        <li>Subcutaneous (SQ) injection</li>
        <li>Dementia care</li>
        <li>Hospice care</li>
      </ul>
      <OptionalServicesImage />
    </div>
    <h2>Languages</h2>
    <ul>
      <li>Staff speak English, Farsi, Turkish, and Tagalog</li>
    </ul>
  </Layout>
);

export default MissionPage;
